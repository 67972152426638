import DOMPurify from 'dompurify';

export const roundDecimalToPercentage = (number: number): number => {
  return typeof number === 'number' ? Math.round(number * 100) : 0;
};

declare global {
  interface Navigator {
    userLanguage: string;
    browserLanguage: string;
  }
}

const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
  }
};

export const formatNumber = ({
  number,
  style = 'decimal',
  minDigits = 0,
  maxDigits = 3,
  locale = 'en-US',
}: {
  number: number;
  style?: 'decimal' | 'percent' | 'spaces';
  locale?: string;
  minDigits?: number;
  maxDigits?: number;
}): string => {
  if (style === 'spaces') {
    const numberString = number.toString();
    const groups = numberString.split(/(?=(?:\d{3})+(?:\.|$))/g);

    return groups.join(' ');
  }

  const numberFormat = new Intl.NumberFormat(locale, {
    style,
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });

  return numberFormat.format(number);
};

export const getCountryName = (retailUnitCode: string, language = 'en'): string => {
  let prefix = '';

  switch (language) {
    case 'en':
      switch (retailUnitCode) {
        case 'US':
          prefix = 'the ';
          break;
        case 'NL':
          prefix = 'the ';
          break;
      }
      break;
  }

  const regionNames = new Intl.DisplayNames([language], { type: 'region' });
  return `${prefix}${regionNames.of(retailUnitCode)}`;
};

export const withTimezoneOffset = (date: Date): Date => {
  return new Date(date.getTime() + new Date().getTimezoneOffset() * 60 * 1000);
};

export const withoutTimezoneOffset = (date: Date): Date => {
  return new Date(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
};

export const formatTimeAgo = (isoDate: string): string => {
  const now = new Date();
  const past = withoutTimezoneOffset(new Date(isoDate));
  const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

  const ranges = {
    year: 3600 * 24 * 365,
    month: 3600 * 24 * 30,
    week: 3600 * 24 * 7,
    day: 3600 * 24,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  type RangeKey = keyof typeof ranges;

  for (const key in ranges) {
    if (diffInSeconds >= ranges[key as RangeKey]) {
      const delta = Math.floor(diffInSeconds / ranges[key as RangeKey]);
      return rtf.format(-delta, key as RangeKey);
    }
  }

  return rtf.format(0, 'second');
};

export const safeHTML = (html: string): string => {
  return DOMPurify.sanitize(html);
};

export const removeDiacritics = (value: string): string => {
  return (
    value
      // Normalize the string
      .normalize('NFD')
      // Remove diacritics
      .replace(/[\u0300-\u036f]/g, '')
  );
};

export const containsDiacritics = (value: string): boolean => {
  return value !== removeDiacritics(value);
};
