<template>
  <div :class="bem()">
    <notifications width="24rem">
      <template #body="props">
        <component :is="props.item.data.component" v-bind="props.item.data.props" :close="props.close"></component>
      </template>
    </notifications>
  </div>
</template>

<script lang="ts" setup>
  import { useBEM } from '@/hooks/use-bem.hook';

  const bem = useBEM('AppNotifications');
</script>

<style lang="scss">
  .AppNotifications {
    margin: 0 auto;
    width: 100%;
    max-width: var(--page-max-width);
    position: sticky;
    top: 0;
    z-index: 5000;

    .vue-notification-group {
      padding: 1rem;
      position: absolute;
    }
  }
</style>
