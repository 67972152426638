export type MenuAboutItem = {
  id: MenuAboutSections;
  label: string;
};

export enum MenuAboutSections {
  FIND_TAIL = 'find-tail',
  AUTOMATIC_TAIL = 'automatic-tail',
  DATA_SOURCES = 'data-sources',
  FUNCTIONAL_GROUPING = 'functional-grouping',
  OTHER_DEFINITIONS = 'other-definitions',
  CONSOLIDATE = 'consolidate',
}
