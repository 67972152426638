export enum QueryParamType {
  FISCAL_YEAR = 'fiscal_year',
  RETAIL_UNIT_CODE = 'retail_unit_code',
  GROUPING = 'grouping',
  GROUPING_PATH = 'grouping_path',
  GROUP_NO_PATH = 'group_no_path',
  GROUPING_OF_APPLIED_KPIS = 'grouping_of_applied_kpis',
  GROUP_NO_OF_APPLIED_KPIS = 'group_no_of_applied_kpis',
  CONFIRMATION_FOR_APPLIED_KPIS = 'confirmation_for_applied_kpis',
  GROUP_ORDER_BY = 'group_order_by',
  GROUP_ORDER_DIRECTION = 'group_order_direction',
  RANGE_PAGE_TAB_VIEW = 'range_page_tab_view',
  MY_PROJECTS_PAGE_TAB_VIEW = 'my_projects_page_tab_view',
  PROBABILITY_OF_BUYING_MIN = 'probability_of_buying_min',
  PROBABILITY_OF_BUYING_MAX = 'probability_of_buying_max',
  REVENUE_PER_RECEIPT_MIN = 'revenue_per_receipt_min',
  REVENUE_PER_RECEIPT_MAX = 'revenue_per_receipt_max',
  QUANTITY_GROWTH_RATE_MIN = 'quantity_growth_rate_min',
  QUANTITY_GROWTH_RATE_MAX = 'quantity_growth_rate_max',
  QUANTITY_PER_RECEIPT_MIN = 'quantity_per_receipt_min',
  QUANTITY_PER_RECEIPT_MAX = 'quantity_per_receipt_max',
  FIVE_PERCENT_RANKING_TAIL = 'five_percent_ranking_tail',
  TEN_PERCENT_RANKING_TAIL = 'ten_percent_ranking_tail',
  AUTO_DETECT_RANKING_TAIL = 'auto_detect_ranking_tail',
  PROJECT_STEP = 'project_step',
  RANGE_LIST = 'range_list',
}
