export const buildQueryString = ({
  retailUnitCode,
  fiscalYear,
  nestingLevelGrouping,
  grouping,
  groupNo,
  articleNumber,
  articleStatus,
  column,
  fivePercentRankingTail,
  tenPercentRankingTail,
  autoDetectRankingTail,
  baseItemNo, 
  itemName,
}: {
  retailUnitCode?: string | null;
  fiscalYear?: string | null;
  nestingLevelGrouping?: string | null;
  grouping?: string | null;
  groupNo?: string | null;
  articleNumber?: string;
  articleStatus?: string | null;
  column?: string | null;
  fivePercentRankingTail?: boolean | null;
  tenPercentRankingTail?: boolean | null;
  autoDetectRankingTail?: boolean | null;
  baseItemNo?: string | null;
  itemName?: string | null;
}): string => {
  const queryParams: string[] = [];

  if (retailUnitCode) {
    queryParams.push(`ru_code=${retailUnitCode}`);
  }

  if (fiscalYear) {
    queryParams.push(`fiscal_year=${fiscalYear}`);
  }

  if (nestingLevelGrouping) {
    queryParams.push(`nesting_level_grouping=${nestingLevelGrouping}`);
  }

  if (grouping) {
    queryParams.push(`grouping=${grouping}`);
  }

  if (groupNo) {
    queryParams.push(`group_no=${groupNo}`);
  }

  if (articleNumber) {
    queryParams.push(`article_number=${articleNumber}`);
  }

  if (articleStatus) {
    queryParams.push(`article_status=${articleStatus}`);
  }

  if (column) {
    queryParams.push(`column=${column}`);
  }

  if (fivePercentRankingTail) {
    queryParams.push(`five_percent_ranking_tail=true`);
  }

  if (tenPercentRankingTail) {
    queryParams.push(`ten_percent_ranking_tail=true`);
  }

  if (autoDetectRankingTail) {
    queryParams.push(`auto_detect_ranking_tail=true`);
  }

  if (baseItemNo) {
    queryParams.push(`base_item_no=${baseItemNo}`);
  }

  if (itemName) {
    queryParams.push(`item_name=${itemName}`);
  }

  return queryParams.join('&');
};
